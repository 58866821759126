import React, {
	useState, useEffect, useCallback
}from "react"
import {
	useSelector, useDispatch
}from "react-redux"
import {
	Badge, notification, Row, Col
}from "antd"
import "antd/lib/badge/style/index.css"
import Global from "../../Global"
import *as signalR from "@microsoft/signalr"
import {
	useHistory
}from "react-router-dom"
import {
	userActions
}from "../../../src/store/actions/user"
import {
	applicantNotificationsActions
}from "./store/actions"
import {
	messageActions
}from "../Messages/store/actions"
import moment from "moment"
import Bowser from 'bowser'
import "antd/lib/notification/style/index.css"
import i18n from "./localization"
import downloadFile from "../../helpers/downloadFile"
import Notify from '../../../public/notifyIcon.svg'
import "./style.css"

const serverUrl =
  process.env.NODE_ENV === "development"
  	? Global.SERVER_SIGNALR_URL
  	: window.location.origin

const userNotifications = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const isIE = (Bowser.name === 'Internet Explorer')
	const getCountNotRead = useCallback(() => dispatch(applicantNotificationsActions.getCountNotRead()), [dispatch])
	const getFirstNotifications = (params) => {
		dispatch(applicantNotificationsActions.getFirstNotifications(params))
	}
	const setRedirectUser = useCallback((redirectUser) => {
		dispatch(messageActions.setRedirectUser({
			redirectUser: redirectUser
		}))
	}, [dispatch])

	const countNotifications = 1
	const isRu = useSelector((state) => state.globalReducer.isRu)
	const { user } = useSelector((state) => state.userReducer)
	const {
		countNotRead,
		notifications,
		loadingFirstNotifications,
		isNotificationFileLoading,
		notificationFile
	} = useSelector((state) => state.applicantNotificationsReducer)
	const [notifyFile, setNotifyApplicantFile] = useState(null)
	const [hub, setHub] = useState(() => {
		const hub = new signalR.HubConnectionBuilder()
			.withUrl(`${ serverUrl }/signalR/`, {
				skipNegotiation : true,
				transport       : signalR.HttpTransportType.WebSockets
			})
			.withAutomaticReconnect([
				0,
				0,
				1000,
				5000,
				10000
			])
			.build()
		hub.keepAliveIntervalInMilliseconds = 1000 * 60 * 5
		hub.serverTimeoutInMilliseconds = 1000 * 60 * 10
		hub.onreconnected((err) => {
			hub.invoke("SetConnection", user.id).catch((err) => console.log(err))
			getCountNotRead()
		})
		hub.on("getNotification", () => {
			getCountNotRead()
		})
		hub.on("refreshPage", (sessionId) => {
			if (sessionId == JSON.parse(window.localStorage.getItem("user"))?.sessionId)
				window.localStorage.removeItem("user")
			window.open("/", "_self")
		})
		return hub
	})

	useEffect(() => {
		if (notificationFile && notifyFile)
			downloadFile(notificationFile, notifyFile)
	}, [notificationFile])

	useEffect(() => {
		return () => {
			dispatch(applicantNotificationsActions.clearNotifications())
		}
	}, [])

	const getNotifyApplicantFile = (file) => {
		if (!isNotificationFileLoading){
			setNotifyApplicantFile(file)
			dispatch(applicantNotificationsActions.downloadNotificationFile({
				id: file.id
			}))
		}
	}

	useEffect(() => {
		if (notifications?.length){
			notifications.forEach((n) => {
				const key = `oneNotification${ Math.floor(Math.random() * Math.floor(999999)) }`
				let target = "_self"
				function getUrl(notify){
					if (!notify.type)
						return "/account"
					switch (notify.type.code){
						case 0 :
							return notify.talker ? "/messages" : "/messagesToAdmin"
						case 1 :
							return `/invitation/${ notify.invitationOrg?.id }`
						case 2 :
							return `/privateJointToAppeal/${ notify.appeal?.id }`
						case 3 :
							target = "_blank"
							return `/appeal/${ notify.reOrgAppeal?.id }`
						case 4 :
							return "/account"
						case 5 :
							return "/account"
						case 6 :
							return `/childOrgInvitation/${ notify.subReOrgInvitation?.id }`
						default :
							return "/"
					}
				}
				const url = getUrl(n)
				notification.open({
					className   : "allNotification",
					description : (
						<div className="notifyDescription">
							<span title={ n.applicantNotificationFiles?.length ? "" : i18n.clickNotifications[isRu] }>
								{ isRu ? n.content : n.contentBel }
							</span>
							<dl>
								{ n.applicantNotificationFiles?.map((file) => (
									<dt key={ file.id }>
										<a
											key={ file.id }
											onClick={ () => getNotifyApplicantFile(file) }
										>
											{ file.fileName + file.fileExtension }
										</a>
									</dt>
								)) }
							</dl>
						</div>
					),
					duration : 300,
					key      : key,
					message  : (
						<span
							className="oneNotification"
							title={ i18n.clickNotifications[isRu] }
							onClick={ () => {
								if (n.type.code == 0 && n.talker?.id)
									setRedirectUser(n.talker.id)
								notification.close(key)
								window.open(url, target)
							} }
						>
							<Row>
								<Col
									className="titleName"
									span={ 17 }
								>
									{ isRu ? n.type.description : n.type.descriptionBel }
								</Col>
								<Col
									className="titleDate"
									span={ 7 }
								>
									{ moment(n.date).format(`DD.MM.YYYY ${ isRu ? "в" : "у" } HH:mm`) }
								</Col>
							</Row>
						</span>
					),
					onClick: () => {
						if (!n.applicantNotificationFiles?.length){
							if (n.type.code == 0 && n.talker?.id)
								setRedirectUser(n.talker.id)
							notification.close(key)
							window.open(url, target)
						}
					},
					placement: "bottomLeft"
				})
			})
			dispatch(applicantNotificationsActions.clearNotifications())
		}
	}, [notifications])

	useEffect(() => {
		if (user){
			hub.start().then(() => {
				hub.invoke("SetConnection", user.id).catch((err) => console.log(err))
				getCountNotRead()
			})
		}
		return () => {
			hub.stop()
		}
	}, [user])

	function showNotifications(){
		if (countNotRead && !loadingFirstNotifications){
			getFirstNotifications({
				count: countNotifications
			})
		}
		else
			history.push("/notifications")
	}

	return (
		<div onClick={ () => showNotifications() }>
			<Badge
				className="notify"
				count={ countNotRead }
				offset={ [5, 0] }
				overflowCount={ 99 }
				showZero={ false }
				style={ { fontSize   : isIE ? "24px" : null,
					lineHeight : isIE ? '18px' : null } }
				title={ i18n.newNotifications[isRu].format(countNotRead) }
			>
				<img
					src={ Notify }
					style={ { verticalAlign: "middle" } }
					title={
						countNotRead
							? i18n.newNotifications[isRu].format(countNotRead)
							: i18n.noneNotifications[isRu]
					}
				/>
			</Badge>
		</div>
	)
}

export default userNotifications
